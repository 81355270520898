@import '../../../styles/customMediaQueries.css';
@import '../../../styles/marketplaceDefaults.css';

.similarListingsContainer {
  position: relative;
  width: 100%;
  padding: 0 24px;

  @media (--viewportSmall) {
    padding: 0 24px;
    width: 100%;
    margin: 0;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 0 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 100%;
  }
}

.similarListingsWrapper {
  display: flex;
  align-items: center;
}

.scrollButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &:first-of-type {
    left: 0;
  }

  &:last-of-type {
    right: 0;
  }
}

.prevIcon {
  scale: -1;
  stroke: var(--spxGreyLight);
}

.similarListingsGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 16px;
  height: 380px;
  padding: 0 16px;

  @media (--viewportSmall) {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 16px;
    height: 380px;
    padding: 0 16px;
  }
}

.similarListingCard {
  width: 300px;

  flex: 0 0 auto;
  margin-right: 16px;
  scroll-snap-align: start;

  @media (--viewportSmall) {
    width: 300px;
    height: 80%;
    flex: 0 0 auto;
    margin-right: 16px;
    scroll-snap-align: start;
  }
}

.nextIcon {
  stroke: var(--spxGreyLight);
}

.noListingsYet {
  text-align: center;
  margin: auto;
}
